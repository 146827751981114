import Choices from 'choices.js'

document.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll('.js-choices')
  if (elements.length) {
    elements.forEach((el, _) => {
      let replaceContainer = false
      if (el.dataset.choice) {
        el.removeAttribute('data-choice')
        replaceContainer = true
      }
      const url = el.dataset.url
      const configChoices = {
        removeItemButton: true,
        noChoicesText: 'No hay opciones para elegir',
        noResultsText: 'No se han encontrado resultados',
        itemSelectText: 'Seleccionar',
        placeholder: true,
        searchPlaceholderValue: el.className.split(' ').includes('write-here') ? 'Escribe aquí' : 'Buscar',
        searchResultLimit: 25
      }

      if (el.className.split(' ').includes('search')) {
        //choices options
        configChoices['classNames'] = {
          containerInner: `choices__inner__custom ${el.className.split(' ').includes('form') ? 'form' : ''}`,
          placeholder: 'placeholder-gray-700'
        }
      }

      const choicesDocuments = new Choices(el, configChoices)
      if (replaceContainer) {
        let father = el.parentNode.parentNode
        let grandfather = father.parentNode.parentNode
        grandfather.replaceWith(father)
      }

      if (el.className.split(' ').includes('search') && url) {
        const handleSearch = async (event, choicesDocuments) => {
          try {
            const response = await fetch(url + event.detail.value, {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' }
            })
            const items = await response.json()
            const options = items.map(item => {
              if (item.slug) {
                return { value: item.id, label: item.name, customProperties: item.slug }
              }
              return { value: item.id, label: item.name }
            })
            choicesDocuments.setChoices(options, 'value', 'label', true)
            setAlertDisplay(!options.length, choicesDocuments)
          } catch (err) {
            console.error(err)
          }
        }
        el.addEventListener(
          'search',
          event => {
            handleSearch(event, choicesDocuments)
          },
          false
        )
      }

      el.addEventListener(
        'change',
        event => {
          if (event.target.length) {
            if (event.target[0].innerText.length) {
              const hiddenNameElement = document.querySelector(`.hidden_name_${el.id}`)
              if (hiddenNameElement) {
                const institutionId = document.getElementById(el.id)
                const institutionIdJs = document.getElementById('report_institution_id_js')
                if (institutionIdJs && institutionIdJs.length) institutionIdJs.value = institutionId.value
                hiddenNameElement.value = event.target[0].innerText
              }
            }
            if (event.target[0].dataset && event.target[0].dataset.customProperties.length) {
              const hiddenSigleElement = document.querySelector(`.hidden_sigle_${el.id}`)
              if (hiddenSigleElement) {
                hiddenSigleElement.value = event.target[0].dataset.customProperties
              }
            }
          }
        },
        false
      )

      el.addEventListener('hideDropdown', () => setDropdowPosition('absolute'))
    })
  }
})

const setAlertDisplay = (show, choices) => {
  const alertBox = document.getElementById('alertBox')

  if (show) {
    setDropdowPosition('relative')
    alertBox.style.display = 'block'
    choices.setChoices([{ value: '', label: 'Selecciona la entidad' }], 'value', 'label', false)
    choices.setChoiceByValue('')
    toggleFormInputs(true)
  } else {
    alertBox.style.display = 'none'
    toggleFormInputs(false)
  }
}

const setDropdowPosition = position => {
  const dropdown = document.querySelector('.choices__list--dropdown')
  dropdown.style.position = position
}

const toggleFormInputs = state => {
  const formSection = document.querySelector('section.form-inputs')
  const inputs = formSection.querySelectorAll('input, select, textarea')
  const dropzone = Dropzone.forElement(formSection.querySelector('input[type="file"]').closest('.dropzone'))
  const submitButton = document.querySelectorAll('button[type="submit"]')[0]

  inputs.forEach((input, index) => (index > 1 ? (input.disabled = state) : null))
  submitButton.disabled = state
  state ? dropzone.disable() : dropzone.enable()
}
