import { Controller } from 'stimulus'

const CGR_ID = '16'
const PCM_ID = '63'

export default class extends Controller {
  changeRoles(event) {
    const institutionId = event.currentTarget.value
    const roles = document.querySelector('#user_role')
    const oc = document.querySelectorAll('[value="OC"]')
    const permitedIds = [CGR_ID, PCM_ID]

    if (!permitedIds.some(id => id === institutionId) && oc.length) {
      for (let i = 0; i < roles.length; i++) {
        if (roles.options[i].value === 'OC') roles.remove(i)
      }
    }

    if (permitedIds.some(id => id === institutionId) && !oc.length) {
      let opt = document.createElement('option')
      opt.value = 'OC'
      opt.innerHTML = 'Operador de Contraloría'
      roles.appendChild(opt)
    }

    for (let i = 0; i < roles.length; i++) {
      if (roles.options[i].value === 'SIP')
        if (institutionId === PCM_ID) {
          //para PCM (id=63) si aparece SIP
          roles.options[i].style.display = ''
        } else {
          roles.options[i].style.display = 'none'
          roles.value = ''
        }
    }
  }
}
